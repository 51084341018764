export class EmailRequest {
  name: string;
  surnames: string;
  email: string;
  phone: string;
  message: string;

  constructor(request: Partial < EmailRequest > ) {
    this.name = '';
    this.surnames = '';
    this.message = '';
    this.email = '';
    this.phone = '';

    if (request != null) {
      Object.assign(this, request);
    }
  }
}
