import { EmailService } from '../../services/email.service';
import { EmailRequest } from './../../models/emailRequest';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {

  contactForm: UntypedFormGroup;
  sendEmailLoading$: boolean;
  buttonDisabled = true;

  constructor(private formBuilder: UntypedFormBuilder, private emailService: EmailService, private snackBar: MatSnackBar, private loadingService: LoadingService) {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      surnames: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      message: ['', [Validators.required]]
    });

    this.sendEmailLoading$ = false;
   }

   messageFieldHasErrors(): boolean {
     if (this.contactForm.controls.message.errors === null) {return false; }
     return (this.contactForm.controls.message.errors.required && this.contactForm.controls.message.touched);
   }

   disableSendButton(): void {
      if (this.contactForm === undefined) { this.buttonDisabled = true; }
      if (this.contactForm.controls.message.errors === null) { this.buttonDisabled = false; } else { this.buttonDisabled = true; }
   }

   sendForm(): void {
    this.loadingService.setLoading(true);
     const request = new EmailRequest(this.contactForm.value);
     this.buttonDisabled = true;
     this.emailService.sendEmail(request).pipe(finalize(() => {
      this.buttonDisabled = false;
      this.loadingService.setLoading(false);
      })).subscribe({
      next: () => {
        this.snackBar.open('Email enviado', 'Cerrar', {
          duration: 2000,
        });
        this.contactForm.reset();
      },
      error: () => {
        this.snackBar.open('No ha sido posible enviar el email', 'Cerrar', {
          duration: 2000,
        });
        this.contactForm.reset();
        this.loadingService.setLoading(false);
      }});
   }
}
