export class CardItem {
  link  : string;
  title: string;
  subtitle: string;
  content: string;
  img: string;

  constructor(request: Partial <CardItem> ) {
    this.link = '';
    this.title = '';
    this.subtitle = '';
    this.content = '';
    this.img = '';

    if (request != null) {
      Object.assign(this, request);
    }
  }
}
