<div class="text-center">
  <h3>¡Bienvenido a mi sitio web!</h3>
</div>
<div class="text-center">
  <img src="./../../../assets/img/cool.png">
  <br>
  <p>No olvides seguirme en redes sociales</p>
  <a href="https://twitter.com/alex_mila99"><img class="socialIcon"
    src="./../../../assets/img/twitter.svg"></a>
  <a href="https://www.linkedin.com/in/%C3%A0lex-mil%C3%A0-sol%C3%ADs-294a61125/"><img class="socialIcon"
    src="./../../../assets/img/linkedin.svg"></a>
  <div style="float:right;" mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cerrar</button>
  </div>
</div>
