import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  showFiller = false;
  languages = ['Català', 'Castellano', 'English'];
  constructor(private router: Router, public readonly loadingService: LoadingService) { }

  ngOnInit() {
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  goToContact() {
    this.router.navigate(['/contactme']);
  }

  goToSkills() {
    this.router.navigate(['/skills']);
  }

}
