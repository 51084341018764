import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading$ = new BehaviorSubject(false)
  public isLoading = this.isLoading$.asObservable();

  public setLoading(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }
}
