import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { SkillspageComponent } from './components/skillspage/skillspage.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainpageComponent } from './components/mainpage/mainpage.component';

const routes: Routes = [
  { path: '', component: MainpageComponent, title: 'Inicio' },
  { path: 'home', component: MainpageComponent, title: 'Inicio' },
  { path: 'skills', component: SkillspageComponent, title: 'Mis Habilidades'},
  // { path: 'contactme', component: ContactPageComponent, title: 'Contáctame'},
  { path: '**', component: MainpageComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
