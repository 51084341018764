import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { SpeedDialFabPosition } from './shared/speed-dial-fab/speed-dial-fab.component';
import { DialogOverviewComponent } from './shared/dialog-overview/dialog-overview.component';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

  // declare ga as a function to access the JS code in TS
  declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Àlex Milà';

  animal: string;
  name: string;

  public speedDialFabButtons = [
    {
      icon: 'chat',
      tooltip: '',
      badgeIsActive: 1,
      visible: true,
    }
  ];

  SpeedDialFabPosition = SpeedDialFabPosition;
  speedDialFabColumnDirection = 'column';
  speedDialFabPosition = SpeedDialFabPosition.Bottom;
  speedDialFabPositionClassName = 'speed-dial-container-top';

  constructor(private dialog: MatDialog, public router: Router) {

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }
  ngOnInit(): void {
    let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#164969"
           },
           button: {
             background: "#ffe000",
             text: "#164969"
           }
         },
         theme: "classic",
         content: {
           message: "Utilizo cookies para asegurar que doy la mejor experiencia al usuario en mi web. Al seguir utilizando este sitio web se asume que está de acuerdo.",
           dismiss: "aceptar",
         }
       });
  }

  onPositionChange(position: SpeedDialFabPosition) {
    switch(position) {
      case SpeedDialFabPosition.Bottom:
        this.speedDialFabPositionClassName = 'speed-dial-container-bottom';
        this.speedDialFabColumnDirection = 'column-reverse';
        break;
      default:
        this.speedDialFabPositionClassName = 'speed-dial-container-top';
        this.speedDialFabColumnDirection = 'column';
    }
  }

  onSpeedDialFabClicked(btn: {icon: string}) {
    this.speedDialFabButtons[0].visible = false;
    this.openDialog();
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(DialogOverviewComponent, dialogConfig);
  }

}

