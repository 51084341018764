<mat-progress-bar *ngIf="loadingService.isLoading | async" mode="indeterminate"></mat-progress-bar>
<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span class="webTitle">Àlex Milà</span>
    <a href="https://twitter.com/alex_mila99"><img class="socialIcon"
        src="./../../../assets/img/twitter.svg"></a>
    <a href="https://www.linkedin.com/in/%C3%A0lex-mil%C3%A0-sol%C3%ADs-294a61125/"><img class="socialIcon"
        src="./../../../assets/img/linkedin.svg"></a>
    <span class="toolbar-spacer"> </span>
    <button mat-icon-button [matMenuTriggerFor]="menu" #t="matMenuTrigger">
      <mat-icon *ngIf="!t.menuOpen">menu</mat-icon>
      <mat-icon *ngIf="t.menuOpen">menu_open</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="goToHome()">
        <mat-icon>home</mat-icon>
        <span>Inicio</span>
      </button>
      <button mat-menu-item (click)="goToSkills()">
        <mat-icon>computer</mat-icon>
        <span>Habilidades</span>
      </button>
      <!-- <button mat-menu-item (click)="goToContact()">
        <mat-icon>contact_mail</mat-icon>
        <span>Contacto</span>
      </button> -->
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
