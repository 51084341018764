<section class="mainpageSection">
  <div class="coverImage">
    <img src="./assets/img/playa.jpg" style="object-fit: contain">
    <h1 class="mainTitle">Software developer</h1>
    <img>
  </div>
  <section class="presentacionSection">
    <h2 class="homeSectionTitle"> Presentación</h2>
    <section class="subsectionPresentacion">
      <p class="textPresentacion">
        Hola! Soy Àlex Milà.
        Soy programador web y me apasiona aprender nuevas tecnologías. Actualmente trabajo como software development engineer en Werfen.
        Estoy especializado en tecnologías .Net (C#) y Angular, entre mis habilidades destacan el desarrollo de APIs,
        aplicaciones de consola y frontends web.
        <br>
        <br>
        Cabe destacar que poseo conocimientos también en Java, PHP, Golang, Python, JavaScript, SQL, Typescript, PowerBI, MongoDB y git.
        Me encanta estar en constante aprendizaje e ir descubriendo todas las posibilidades que la tecnología ofrece para
        el desarrollo y mejora de la sociedad.
      </p>
      <div class="imgDivPresentacion"></div>
    </section>
  </section>
  <section class="bcnSection"></section>
  <h2 class="homeSectionTitle">Colaboraciones</h2>
  <div class="containerColab">
    <ng-container *ngFor="let item of cardItems">
      <div class="colabItem">
        <a [href]="item.link">
          <mat-card class="example-card" style="box-shadow: 0 0 10px transparent; background-color: #111422; color: white; height: 100%;">
            <mat-card-header>
              <div mat-card-avatar class="example-header-image"></div>
              <mat-card-title>{{item.title}}</mat-card-title>
              <mat-card-subtitle style="color:white">{{item.subtitle}}</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image [src]="item.img" alt="">
            <mat-card-content>
              <p style="text-align: justify;">
                {{item.content}}
              </p>
            </mat-card-content>
          </mat-card>
        </a>
      </div>
    </ng-container>
  </div>
</section>
