import { NgModule } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatBadgeModule} from '@angular/material/badge';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';


@NgModule({
  imports: [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatMenuModule, MatButtonToggleModule,
            MatTooltipModule, MatCardModule, MatDividerModule, MatListModule, MatSidenavModule, MatSelectModule, MatInputModule,
            MatFormFieldModule, MatSnackBarModule, MatProgressSpinnerModule, MatBadgeModule, MatDialogModule, MatProgressBarModule],
  exports: [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatIconModule, MatMenuModule, MatButtonToggleModule,
            MatTooltipModule, MatCardModule, MatDividerModule, MatListModule, MatSidenavModule, MatSelectModule, MatInputModule,
            MatFormFieldModule, MatSnackBarModule, MatProgressSpinnerModule, MatBadgeModule, MatDialogModule, MatProgressBarModule],
})
export class MaterialModule { }
