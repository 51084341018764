import { CardItem } from './../../models/card-item.type';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent implements OnInit {

  cardItems: Array<CardItem> = [];

  constructor(private meta: Meta, private router: Router) {
    this.meta.addTags([
      {name: 'description', content: 'Página personal de Àlex Milà, desarrollador web en Barcelona. Especializado en Angular y tecnologías .Net'},
      {name: 'author', content: 'Àlex Milà'},
      {name: 'keywords', content: 'Web developer, desarrollador, programador barcelona, angular, frontend, .net'}
    ]);

    this.cardItems.push(new CardItem({ title: 'Mediarc.cat', subtitle: 'Wordpress', content: 'En está colaboración me encargué de realizar la creación del sitio wordpress así como de su instalación y edición de un tema para poder desarrollar esta web.',
    img: './../../../assets/img/mediarc.png', link: "http://mediarc.cat" }));
    this.cardItems.push(new CardItem({ title: 'Canaltenis.com', subtitle: 'Wordpress', content: 'En está colaboración he realizado ediciones en el tema de wordpress, tanto a nivel de optimización como de diseño. Por otro lado, he gestionado la colocación de banners publicitarios y la optimización del SEO del sitio web.',
    img: './../../../assets/img/canaltenis.png', link: "https://canaltenis.com" }));
    this.cardItems.push(new CardItem({ title: 'alexmilasolis.com', subtitle: 'Angular', content: 'Mi sitio web personal, realizado con Angular, Material y Express.js.',
    img: './../../../assets/img/alexmilasolis.png', link: "/" }));
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
}
}
