<section class="skillsSection">
  <section class="skillsArticle">
    <h2>Habilidades</h2>
    <div class="skillsItemContainer">
        <div class="skillItem">
          <img src="./../../../assets/img/angular.svg.png">
        </div>
        <div class="skillItem">
          <img src="./../../../assets/img/netcorelogo.png">
        </div>
        <div class="skillItem">
          <img src="./../../../assets/img/C_sharp.png">
        </div>
        <div class="skillItem">
          <img src="./../../../assets/img/sql.png">
        </div>
        <div class="skillItem">
          <img src="./../../../assets/img/php.svg.png">
        </div>
        <div class="skillItem">
          <img src="./../../../assets/img/python.png">
        </div>
        <div class="skillItem">
          <img src="./../../../assets/img/bootstrap.png">
        </div>
        <div class="skillItem">
          <img src="./../../../assets/img/mongodb.png">
        </div>
      </div>
  </section>
</section>
