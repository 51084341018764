import { EmailRequest } from '../models/emailRequest';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  routeAPI: string;

  constructor(private http: HttpClient) {
    this.routeAPI = environment.apiUrl;
  }


  sendEmail(request: EmailRequest) {
    const authorizationData = 'Basic ' + btoa('almiso19:bgkdsbfu194842.1');
    return this.http.post(`${this.routeAPI}/service/send-email`, request, { headers: new HttpHeaders({Authorization: authorizationData})});
  }
}
