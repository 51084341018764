<section class="contactSection">
  <section class="contactArticle">
    <h2>Contáctame</h2>
    <form [formGroup]="contactForm" class="example-form">

      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input formControlName="name" matInput>
        <mat-error *ngIf="contactForm.get('name').errors">
          <p *ngIf="contactForm.get('name').hasError('required')">
            Nombre obligatorio*
          </p>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Apellidos</mat-label>
        <input formControlName="surnames" matInput>
        <mat-error *ngIf="contactForm.get('surnames').errors">
          <p *ngIf="contactForm.get('surnames').hasError('required')">
            Apellidos obligatorios*
          </p>
        </mat-error>
      </mat-form-field>

        <mat-form-field>
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput>
          <mat-error *ngIf="contactForm.get('email').errors">
            <p *ngIf="contactForm.get('email').hasError('required')">
              Email obligatorio*
            </p>
            <p *ngIf="contactForm.get('email').hasError('email')">
              Email no válido
            </p>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Teléfono</mat-label>
          <input formControlName="phone" matInput>
        </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Mensaje</mat-label>
        <textarea formControlName="message" matInput></textarea>
        <mat-error *ngIf="contactForm.get('message').errors">
          <p *ngIf="messageFieldHasErrors()">
            Mensaje obligatorio*
          </p>
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" class="sendButton" [disabled]="buttonDisabled" (click)="sendForm()">
        Enviar
      </button>
    </form>
  </section>
  {{disableSendButton()}}
</section>
